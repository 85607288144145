import React from 'react' 
import Helmet from 'react-helmet' 
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content from "../components/content"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import ShareLinks from '../components/ShareLinks'


const blogPost = ({data}) => {
const post = data.graphAPI.blogPost
function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

    return (
    <Layout>
        <Helmet>
            <meta property="og:url"                content={"http://alerts.hcmud401.tsds.info/"+new Date(post.publishedAt).toISOString().substring(0,10)+"/"+slugify(post.title)} />
            <meta property="og:type"               content="article" />
            <meta property="og:title"              content={post.title} />
            <meta property="og:description"        content={post.description} />
            {/* { (post.featuredImage !== null) ? ( <meta property="og:image"              content={post.featuredImage.sourceUrl} />) : (<meta property="og:image" content="" />)} */}
        </Helmet>
        <section className="section">
            <div className="container content">
                <div className="columns">
                    <div className="column is-8 is-offset-1">
                        <h1 className="title is-size-1 is-marginless">{post.title}</h1>
                        <p>Posted: {new Date(post.publishedAt).toLocaleString()}</p>
                        <div class="dropdown is-hoverable" style={{marginBottom: '0'}}>
                            <div class="dropdown-trigger">
                                <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                                <span>Share</span>
                                <span className="icon is-small">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                                <div class="dropdown-content">
                                <ShareLinks slug={"/"+new Date(post.publishedAt).toISOString().substring(0,10)+"/"+slugify(post.title)} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <Content content={post.content} />
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)}

export default blogPost

export const blogPostQuery = graphql`
    query BlogPost($id: ID!) {
        graphAPI {
            blogPost(id: $id) {
                id
                title
                description
                publishedAt
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
        }
    }
`